body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.qr {
  /* transform: translateX(-50%) translateY(-50%); */
  /* top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto; */
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  /* position: absolute; */

}


.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.qr-overlay {
  height: 100vh;  
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.4);
}

.box{
  border: 3px solid white;
  width: 300px;
  height: 300px;
  background-color: rgba(255,255,255,.4);
  position: relative;
  border-radius: 20px;
}

.form{
  padding: 0 20px;
}

.thanks{
  height: 90vh;
  display: flex;
  justify-content:  center;
  align-items: center;
  flex-direction: column;
}

.emoji{
  width: 80px;
  height: 80px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  margin-bottom: 10px;
  border:1px solid green;
  border-radius: 50%;
}